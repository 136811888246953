import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
} from '@backstage/core-plugin-api';

import {
  myServiceCatalogCreateRouteRef,
  myServiceCatalogListRouteRef,
  myServiceCatalogDetailViewRouteRef,
} from './routes';
import { MyServiceCatalogApiClient, myServiceCatalogApiRef } from './api';

export const serviceCatalogPlugin = createPlugin({
  id: 'service-catalog',
  routes: {
    root: myServiceCatalogCreateRouteRef,
    litPage: myServiceCatalogListRouteRef,
    detailPage: myServiceCatalogDetailViewRouteRef,
  },
  apis: [
    createApiFactory({
      api: myServiceCatalogApiRef,
      deps: { discoveryApi: discoveryApiRef },
      factory: ({ discoveryApi }) =>
        new MyServiceCatalogApiClient({ discoveryApi }),
    }),
  ],
});

export const ServiceCatalogCreatePage = serviceCatalogPlugin.provide(
  createRoutableExtension({
    name: 'ServiceCatalogCreatePage',
    component: () =>
      import('./components/ServiceCatalogCreate').then(
        m => m.ServiceCatalogCreate,
      ),
    mountPoint: myServiceCatalogCreateRouteRef,
  }),
);

export const ServiceCatalogListPage = serviceCatalogPlugin.provide(
  createRoutableExtension({
    name: 'ServiceRequestListPage',
    component: () =>
      import('./components/ServiceCatalogListView').then(
        m => m.ServiceCatalogListView,
      ),
    mountPoint: myServiceCatalogListRouteRef,
  }),
);

export const ServiceRequestDetailViewPage = serviceCatalogPlugin.provide(
  createRoutableExtension({
    name: 'ServiceRequestDeatilViewPage',
    component: () =>
      import('./components/ServiceCatalogDetailView').then(
        m => m.ServiceCatalogDetailView,
      ),
    mountPoint: myServiceCatalogDetailViewRouteRef,
  }),
);
