import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
} from '@backstage/core-plugin-api';

import {
  mySystemFormRouteRef,
} from './routes';
import { MySystemApiClient, mySystemApiRef } from './api';

export const systemPlugin = createPlugin({
  id: 'system',
  routes: {
    root: mySystemFormRouteRef,
  },
  apis: [
    createApiFactory({
      api: mySystemApiRef,
      deps: { discoveryApi: discoveryApiRef },
      factory: ({ discoveryApi }) =>
        new MySystemApiClient({ discoveryApi }),
    }),
  ],
});

export const SystemCreationPage = systemPlugin.provide(
  createRoutableExtension({
    name: 'SystemCreationPage',
    component: () =>
      import('./components/SystemCreation').then(m => m.default),
    mountPoint: mySystemFormRouteRef,
  }),
);
