import { DiscoveryApi, createApiRef } from '@backstage/core-plugin-api';
import { ErrorCatalogListType } from './interface';

export interface MyServiceRequestApi {
  getErrorCatalogList: (payload: any) => Promise<ErrorCatalogListType>;
}

export const myServiceRequestApiRef = createApiRef<MyServiceRequestApi>({
  id: 'plugin.errorcatalog-list.service',
});

export class MyServiceRequestApiClient implements MyServiceRequestApi {
  discoveryApi: DiscoveryApi;

  constructor({ discoveryApi }: { discoveryApi: DiscoveryApi }) {
    this.discoveryApi = discoveryApi;
  }

  private async fetch<T = any>(input: string, init?: RequestInit): Promise<T> {
    // As configured previously for the backend proxy
    const proxyUri = `${await this.discoveryApi.getBaseUrl('errorcataloglist')}`;
    const resp = await fetch(`${proxyUri}${input}`, init);
    if (!resp.ok) throw new Error(resp as any);
    return await resp.json();
  }

  async getErrorCatalogList(payload: any): Promise<ErrorCatalogListType> {
    return await this.fetch<ErrorCatalogListType>('/errorcatalog-list', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });
  }
}
