import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
} from '@backstage/core-plugin-api';

import {
  myVendorFormRouteRef,
} from './routes';
import { MyVendorApiClient, myVendorApiRef } from './api';

export const vendorPlugin = createPlugin({
  id: 'vendor',
  routes: {
    root: myVendorFormRouteRef,
  },
  apis: [
    createApiFactory({
      api: myVendorApiRef,
      deps: { discoveryApi: discoveryApiRef },
      factory: ({ discoveryApi }) =>
        new MyVendorApiClient({ discoveryApi }),
    }),
  ],
});

export const VendorCreationPage = vendorPlugin.provide(
  createRoutableExtension({
    name: 'VendorCreationPage',
    component: () =>
      import('./components/VendorCreation').then(m => m.default),
    mountPoint: myVendorFormRouteRef,
  }),
);
