/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { createPermission } from '@backstage/plugin-permission-common';

/**
 * An example of a permission.
 *
 * @public
 */
export const eventCreationPermission = createPermission({
  name: 'event.create',
  attributes: {action: 'create'}
})

export const eventVendorViewPermission = createPermission({
  name: 'vendor.event.view',
  attributes: {action: 'read'}
})

export const eventAdminViewPermission = createPermission({
  name: 'admin.event.view',
  attributes: {action: 'read'}
})

export const eventSubscriberCreate = createPermission({
  name: 'event.subscriber.create',
  attributes: {action: 'create'}
})

export const eventPublisherCreate = createPermission({
  name: 'event.publisher.create',
  attributes: {action: 'create'}
})

export const eventSubscriberEdit = createPermission({
  name: 'event.subscriber.edit',
  attributes: {action: 'update'}
})

export const eventPublisherEdit = createPermission({
  name: 'event.publisher.edit',
  attributes: {action: 'update'}
})

export const eventGridPermissions = [
  eventCreationPermission,
  eventVendorViewPermission,
  eventAdminViewPermission,
  eventSubscriberCreate,
  eventPublisherCreate,
  eventSubscriberEdit,
  eventPublisherEdit,
];
