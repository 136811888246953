/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { createPermission } from '@backstage/plugin-permission-common';

/**
 * An example of a permission.
 *
 * @public
 */
export const vendorCreateApiCatalogPermission = createPermission({
  name: 'vendor.catalog.create',
  attributes: { action: 'create' },
});

export const vendorViewApiCatalogPermission = createPermission({
  name: 'vendor.catalog.view',
  attributes: { action: 'read' },
});

export const adminCreateApiCatalogPermission = createPermission({
  name: 'admin.catalog.create',
  attributes: { action: 'create' },
});

export const apiCatalogPermissions = [
  vendorCreateApiCatalogPermission,
  vendorViewApiCatalogPermission,
  adminCreateApiCatalogPermission,
];
