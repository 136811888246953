import {
  createApiRef,
  DiscoveryApi,
  IdentityApi,
} from '@backstage/core-plugin-api';

export interface TeamAdministrationApi {
  getAdminTeamList: () => Promise<any>;
  getTeamGroup: () => Promise<any>;
  saveTeamGroup: (payload: any) => Promise<any>;
  mapAzureGroupWithLocalTeam: any;
  getAllVendors: any;
  getSystemDetail: any;
}

export const teamAdministrationApiRef = createApiRef<TeamAdministrationApi>({
  id: 'plugin.team-administration.service',
});

export class TeamAdministrationClient {
  discoveryApi: DiscoveryApi;
  identityApi: IdentityApi;

  constructor({
    discoveryApi,
    identityApi,
  }: {
    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;
  }) {
    this.discoveryApi = discoveryApi;
    this.identityApi = identityApi;
  }

  private async fetch<T = any>(input: string, init?: RequestInit): Promise<T> {
    // As configured previously for the backend proxy
    const proxyUri = `${await this.discoveryApi.getBaseUrl(
      'teamadministration',
    )}`;

    const resp = await fetch(`${proxyUri}${input}`, init);
    if (!resp.ok) throw new Error(resp as any);
    return await resp.json();
  }

  async getAdminTeamList(): Promise<any[]> {
    try {
      const rowGroupsData = await this.getAzureAdminGroups();
      const azureGroupList = this.getGroupNameList(rowGroupsData);

      const localTeamGroup = await this.getTeamGroup();

      return this.mapAzureGroupWithLocalTeam({
        azureGroupList,
        localTeamGroup,
      });
    } catch (error) {
      return [];
    }
  }

  async getTeamGroup(): Promise<any[]> {
    return await this.fetch<any[]>('/team-group');
  }

  async saveTeamGroup(payload: any): Promise<any> {
    return await this.fetch('/team-group', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });
  }

  mapAzureGroupWithLocalTeam({ azureGroupList, localTeamGroup }) {
    return azureGroupList.map((teamGroupName: any) => {
      const findTeamGroup = localTeamGroup.find(
        (item: { teamGroup: any }) => teamGroupName === item.teamGroup,
      );

      if (findTeamGroup) {
        return findTeamGroup;
      }

      return { teamGroup: teamGroupName, teamGroupDL: '', teamGroupName: '' };
    });
  }

  async getAzureAdminGroups(): Promise<any[]> {
    const { token } = await this.identityApi.getCredentials();

    const url = `${await this.discoveryApi.getBaseUrl(
      'catalog',
    )}/entities?filter=kind=user&filter=kind=group`;

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch groups: ${response.statusText}`);
    }

    return response.json();
  }

  getGroupNameList(data: any[]) {
    // Create a Set to store unique group names
    const uniqueGroupNames = new Set();

    // Iterate through the data to find group names
    data.forEach(item => {
      if (item.kind === 'User') {
        item.spec.memberOf.forEach((group: string) => {
          uniqueGroupNames.add(group.split('/')[1]);
        });
      } else if (item.kind === 'Group') {
        uniqueGroupNames.add(item.metadata.name);
      }
    });

    // Convert the Set to an Array and log the unique group names
    const uniqueGroupNamesArray = Array.from(uniqueGroupNames);

    return uniqueGroupNamesArray;
  }

  async getAllVendors(): Promise<any[]> {
    return await this.fetch<any[]>('/getallvendors');
  }
  async getSystemDetail(vendorRefId: string): Promise<any[]> {
    return await this.fetch<any[]>(
      `/getsystemdetail?vendorRefId=${vendorRefId}`,
    );
  }
}
