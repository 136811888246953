import { createRouteRef } from '@backstage/core-plugin-api';

export const myEventGridFormRouteRef = createRouteRef({
  id: 'event-grid',
});

export const myEventGridEditRouteRef = createRouteRef({
  id: 'event-grid-edit',
  params: ['id'],
});

export const myEventGridListRouteRef = createRouteRef({
  id: 'event-grid-list',
});

export const myEventGridDetailRouteRef = createRouteRef({
  id: 'event-grid-detail',
  params: ['id'],
});
