import { DiscoveryApi, createApiRef } from '@backstage/core-plugin-api';
import {
  EventGridSubscription,
  EventGridSubscriptionResponse,
} from './interface';

export interface MyEventGridApi {
  getEventGridDetails: () => Promise<EventGridSubscription[]>;
  saveEventGrid: (payload: any) => Promise<EventGridSubscriptionResponse[]>;
  getSubscriberbyId: (payload: any) => Promise<any[]>;
  getPublisherbyId: (payload: any) => Promise<any[]>;
  saveSubscriber: (payload: any) => Promise<any[]>;
  savePublisher: (payload: any) => Promise<any[]>;
  updateSubscriber: (payload: any) => Promise<any[]>;
  updatePublisher: (payload: any) => Promise<any[]>;
  getQAReport: () => Promise<any>;
  getAllServiceRequests: () => Promise<any[]>;
}

export const myEventGridApiRef = createApiRef<MyEventGridApi>({
  id: 'plugin.event-grid.service',
});

export class MyEventGridApiClient implements MyEventGridApi {
  discoveryApi: DiscoveryApi;

  constructor({ discoveryApi }: { discoveryApi: DiscoveryApi }) {
    this.discoveryApi = discoveryApi;
  }

  private async fetch<T = any>(input: string, init?: RequestInit): Promise<T> {
    // As configured previously for the backend proxy
    const proxyUri = `${await this.discoveryApi.getBaseUrl('eventgrid')}`;

    const resp = await fetch(`${proxyUri}${input}`, init);
    if (!resp.ok) throw new Error(resp as any);
    return await resp.json();
  }

  async saveEventGrid(payload: any): Promise<EventGridSubscriptionResponse[]> {
    return await this.fetch<EventGridSubscriptionResponse[]>(
      '/eventgridsubscription',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      },
    );
  }

  async getEventGridDetails(): Promise<EventGridSubscription[]> {
    return await this.fetch<EventGridSubscription[]>('/eventgriddetails');
  }

  async getSubscriberbyId(id: any): Promise<any> {
    const params = new URLSearchParams({
      id: `${id}`,
    });
    return await this.fetch<any>(`/eventsubscriber?${params}`);
  }

  async getPublisherbyId(id: any): Promise<any> {
    const params = new URLSearchParams({
      id: `${id}`,
    });
    return await this.fetch<any>(`/eventpublisher?${params}`);
  }

  async saveSubscriber(payload: any): Promise<any> {
    return await this.fetch<any>('/saveeventsubscriber', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });
  }

  async savePublisher(payload: any): Promise<any> {
    return await this.fetch<any>('/saveeventpublisher', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });
  }

  async updateSubscriber(payload: any): Promise<any> {
    return await this.fetch<any>('/updateeventsubscriber', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });
  }

  async updatePublisher(payload: any): Promise<any> {
    return await this.fetch<any>('/updateeventpublisher', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });
  }

  async getAllServiceRequests(): Promise<any[]> {
    return await this.fetch<any[]>(`/service-requests`);
  }
}
