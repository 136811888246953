import { createRouteRef } from '@backstage/core-plugin-api';

export const myServiceRequstFormRouteRef = createRouteRef({
  id: 'service-request',
});

export const myServiceRequestEditRouteRef = createRouteRef({
  id: 'service-request-edit',
  params: ['id'],
});

export const myServiceRequestListRouteRef = createRouteRef({
  id: 'service-request-list',
});

export const myServiceRequestDetailRouteRef = createRouteRef({
  id: 'service-request-detail',
  params: ['id'],
});
