import {
  configApiRef,
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import { TeamAdministrationClient, teamAdministrationApiRef } from './api';
import {
  createApiFactory,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const teamAdministrationPlugin = createPlugin({
  id: 'team-administration',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: teamAdministrationApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        identityApi: identityApiRef,
        configApi: configApiRef,
      },
      factory: ({ discoveryApi, identityApi }) =>
        new TeamAdministrationClient({ discoveryApi, identityApi }),
    }),
  ],
});

export const TeamAdministrationPage = teamAdministrationPlugin.provide(
  createRoutableExtension({
    name: 'TeamAdministrationPage',
    component: () =>
      import('./components/ExampleComponent').then(m => m.GroupsList),
    mountPoint: rootRouteRef,
  }),
);
