import {
  createUnifiedTheme,
  palettes,
  genPageTheme,
  shapes,
} from '@backstage/theme';

export const myTheme = createUnifiedTheme({
  fontFamily: 'Brown, Arial, sans-serif',
  palette: {
    ...palettes.light, // Take everything from the default light theme, then change what you want
    primary: {
      main: '#EBC03F',
    },
    secondary: {
      main: '#B89535',
      //main: '#d3d'
    },
    background: {
      default: '#FFFFFF',
      //default: '#343538'
    },
    navigation: {
      background: '#070809',
      indicator: '#EBC03F',
      selectedColor: '#FFFFFF',
      color: '#D2D5D7',
      navItem: {
        hoverBackground: '#1D1F22',
      },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none', // Remove uppercase text
        },
        containedPrimary: {
          backgroundColor: '#070809',
          '&:hover': {
            backgroundColor: '#1D1F22', // Slightly darker red on hover
          },
          color: '#FFFFFF !important',
        },
        containedSecondary: {
          backgroundColor: '#FB4341',
          '&:hover': {
            backgroundColor: '#D62020', // Slightly darker red on hover
          },
          color: '#FFFFFF',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#2122ff',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: '#000000 !important',
          backgroundColor: '#d2e3ee !important',
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          background: '#eff5fb !important',
        },
      },
    },
    BackstageHeader: {
      styleOverrides: {
        header: {
          background: '#ffffff !important',
        },
        title: {
          color: '#000000',
        },
      },
    },
  },
  defaultPageTheme: 'home',
  pageTheme: {
    home: genPageTheme({ colors: ['#8c4351', '#343b58'], shape: shapes.wave }),
  },
});
