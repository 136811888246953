import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
} from '@backstage/core-plugin-api';

import {
  myEventGridFormRouteRef,
  myEventGridListRouteRef,
  myEventGridEditRouteRef,
  myEventGridDetailRouteRef,
} from './routes';
import { MyEventGridApiClient, myEventGridApiRef } from './api';

export const eventGridPlugin = createPlugin({
  id: 'event-grid',
  routes: {
    root: myEventGridFormRouteRef,
    newPage: myEventGridListRouteRef,
  },
  apis: [
    createApiFactory({
      api: myEventGridApiRef,
      deps: { discoveryApi: discoveryApiRef },
      factory: ({ discoveryApi }) =>
        new MyEventGridApiClient({ discoveryApi }),
    }),
  ],
});

export const EventGridPage = eventGridPlugin.provide(
  createRoutableExtension({
    name: 'EventGridPage',
    component: () =>
      import('./components/EventGrid/EventGrid').then(m => m.default),
    mountPoint: myEventGridFormRouteRef,
  }),
);

export const EventGridEditPage = eventGridPlugin.provide(
  createRoutableExtension({
    name: 'EventGridEditPage',
    component: () =>
      import('./components/EventGrid/EventGrid').then(m => m.default),
    mountPoint: myEventGridEditRouteRef,
  }),
);

export const EventGridListPage = eventGridPlugin.provide(
  createRoutableExtension({
    name: 'EventGridListPage',
    component: () =>
      import('./components/EventGridList/pages/EventGridListView').then(
        m => m.default,
      ),
    mountPoint: myEventGridListRouteRef,
  }),
);

// export const EventGridDetailPage = eventGridPlugin.provide(
//   createRoutableExtension({
//     name: 'EventGridDetail',
//     component: () =>
//       import('./components/EventGridDetail/EventGridDetail').then(
//         m => m.default,
//       ),
//     mountPoint: myEventGridDetailRouteRef,
//   }),
// );
