// import axios from 'axios';

import { DiscoveryApi, createApiRef } from '@backstage/core-plugin-api';
import { Resource, ResourceType } from './interface';

export interface MyServiceRequestApi {
  getFormContent: () => Promise<ResourceType[]>;
  getEventGridForm: () => Promise<any>;
  getTeamGroups: () => Promise<any>;
  saveEventGridSubscription: (payload: any) => Promise<any>;
  saveServiceRequest: (payload: any) => Promise<ResourceType[]>;
  getAllServiceRequests: (team: string) => Promise<Resource[]>;
  updateRequestInfo: (payload: any) => Promise<Resource[]>;
  getServiceRequestById: (payload: any) => Promise<Resource>;
  getProvisionResourceDetailsRequest: () => Promise<ResourceType[]>;
  saveProvisionResourceRequest: (payload: any) => Promise<Resource>;
}

export const myServiceRequestApiRef = createApiRef<MyServiceRequestApi>({
  id: 'plugin.service-request.service',
});

export class MyServiceRequestApiClient implements MyServiceRequestApi {
  discoveryApi: DiscoveryApi;

  constructor({ discoveryApi }: { discoveryApi: DiscoveryApi }) {
    this.discoveryApi = discoveryApi;
  }

  private async fetch<T = any>(input: string, init?: RequestInit): Promise<T> {
    // As configured previously for the backend proxy
    const proxyUri = `${await this.discoveryApi.getBaseUrl('servicerequest')}`;

    const resp = await fetch(`${proxyUri}${input}`, init);
    if (!resp.ok) throw new Error(resp as any);
    return await resp.json();
  }

  async getFormContent(): Promise<ResourceType[]> {
    return await this.fetch<ResourceType[]>('/form-content');
  }

  async getTeamGroups(): Promise<any> {
    return await this.fetch<any>('/teamgroups');
  }

  async saveServiceRequest(payload: any): Promise<ResourceType[]> {
    return await this.fetch<ResourceType[]>('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });
  }

  async getEventGridForm(): Promise<ResourceType[]> {
    return await this.fetch<ResourceType[]>('/eventgriddetails');
  }

  async saveEventGridSubscription(payload: any): Promise<ResourceType[]> {
    return await this.fetch<ResourceType[]>('/eventgridsubscription', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });
  }

  async getServiceRequestById(id: string): Promise<Resource> {
    return await this.fetch<Resource>(`/${id}`);
  }

  async getAllServiceRequests(team: string): Promise<Resource[]> {
    const params = team ? new URLSearchParams({ team }) : '';

    return await this.fetch<Resource[]>(`?${params}`);
  }

  async updateRequestInfo(payload: any): Promise<Resource[]> {
    return this.fetch<Resource[]>('/', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });
  }

  async getProvisionResourceDetailsRequest(): Promise<ResourceType[]> {
    return this.fetch<ResourceType[]>('/provisionresources/details');
  }

  async saveProvisionResourceRequest(payload: any): Promise<ResourceType[]> {
    return await this.fetch<ResourceType[]>('/provisionresources/save', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });
  }
}
