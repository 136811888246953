import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
} from '@backstage/core-plugin-api';

import {
  myServiceRequstFormRouteRef,
  myServiceRequestListRouteRef,
  myServiceRequestEditRouteRef,
  myServiceRequestDetailRouteRef,
} from './routes';
import { MyServiceRequestApiClient, myServiceRequestApiRef } from './api';

export const serviceRequestPlugin = createPlugin({
  id: 'service-request',
  routes: {
    root: myServiceRequstFormRouteRef,
    newPage: myServiceRequestListRouteRef,
  },
  apis: [
    createApiFactory({
      api: myServiceRequestApiRef,
      deps: { discoveryApi: discoveryApiRef },
      factory: ({ discoveryApi }) =>
        new MyServiceRequestApiClient({ discoveryApi }),
    }),
  ],
});

export const ServiceRequestPage = serviceRequestPlugin.provide(
  createRoutableExtension({
    name: 'ServiceRequestPage',
    component: () =>
      import('./components/ServiceRequest/ServiceRequest').then(m => m.default),
    mountPoint: myServiceRequstFormRouteRef,
  }),
);

export const ServiceRequestEditPage = serviceRequestPlugin.provide(
  createRoutableExtension({
    name: 'ServiceRequestEditPage',
    component: () =>
      import('./components/ServiceRequest/ServiceRequest').then(m => m.default),
    mountPoint: myServiceRequestEditRouteRef,
  }),
);

export const ServiceRequestListPage = serviceRequestPlugin.provide(
  createRoutableExtension({
    name: 'ServiceRequestListPage',
    component: () =>
      import('./components/ServiceRequestList/ServiceRequestList').then(
        m => m.default,
      ),
    mountPoint: myServiceRequestListRouteRef,
  }),
);

export const ServiceRequestDetailPage = serviceRequestPlugin.provide(
  createRoutableExtension({
    name: 'ServiceRequestDetail',
    component: () =>
      import('./components/ServiceRequestDetail/ServiceRequestDetail').then(
        m => m.default,
      ),
    mountPoint: myServiceRequestDetailRouteRef,
  }),
);
