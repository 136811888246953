import React, { PropsWithChildren, useState, ReactNode } from 'react';
import {
  makeStyles,
  Collapse,
  ListItem,
  List,
  Typography,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandMore';
import ExpandMore from '@material-ui/icons/ExpandLess';
import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
import MapIcon from '@material-ui/icons/MyLocation';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import Kitchen from '@material-ui/icons/Kitchen';
import Ballot from '@material-ui/icons/Ballot';

import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import GroupIcon from '@material-ui/icons/Group';

import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
  useSidebarOpenState,
  Link,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';

import { Administration } from '@janus-idp/backstage-plugin-rbac';

import {
  adminViewServiceRequestPermission,
  serviceRequestCreatePermission,
  serviceRequestViewPermission,
} from '@internal/backstage-plugin-service-request-common';

import {
  eventCreationPermission,
  eventVendorViewPermission,
  eventAdminViewPermission,
} from '@internal/backstage-plugin-event-grid-common';

import { vendorCreationPermission } from '@internal/backstage-plugin-vendor-common';
import { systemCreationPermission } from '@internal/backstage-plugin-system-common';

import {
  RequirePermission,
  usePermission,
} from '@backstage/plugin-permission-react';
import {
  adminCreateApiCatalogPermission,
  vendorCreateApiCatalogPermission,
  vendorViewApiCatalogPermission,
} from '@internal/backstage-plugin-service-catalog-common';
import { policyEntityReadPermission } from '@janus-idp/backstage-plugin-rbac-common';
import Settings from '@material-ui/icons/Settings';

interface CollapsibleSidebarGroupProps {
  icon: React.ElementType;
  text: string;
  children: ReactNode;
}
const useSidebarLogoStyles = makeStyles({
  root: {
    margin: '0 auto',
    width: '112px',
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
  whiteText: {
    color: '#b5b5b5',
    fontSize: '14px',
    fontWeight: '500',
  },
});

const CollapsibleSidebarGroup: React.FC<CollapsibleSidebarGroupProps> = ({
  icon: Icon,
  text,
  children,
}) => {
  const classes = useSidebarLogoStyles();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div style={{ padding: '0 10px', width: '100%' }}>
      <ListItem button onClick={handleClick}>
        <Icon className={classes.whiteText} style={{ marginRight: '16px' }}>
          <Icon className={classes.whiteText} />
        </Icon>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          <Typography style={{ color: '#ffffff', fontWeight: '500' }}>
            {text}
          </Typography>
          <div>
            {open ? (
              <ExpandLess className={classes.whiteText} />
            ) : (
              <ExpandMore className={classes.whiteText} />
            )}
          </div>
        </div>
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </div>
  );
};

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link
        id="logo-home"
        to="/"
        underline="none"
        className={classes.link}
        aria-label="Home"
      >
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => {
  // ----------Service Request
  const { allowed: canCreateServiceRequest } = usePermission({
    permission: serviceRequestCreatePermission,
  });
  const { allowed: canViewServiceRequest } = usePermission({
    permission: serviceRequestViewPermission,
  });

  const { allowed: canAdminViewServiceRequest } = usePermission({
    permission: adminViewServiceRequestPermission,
  });

  const showServiceRequestSidebarItem =
    canCreateServiceRequest ||
    canViewServiceRequest ||
    canAdminViewServiceRequest;

  // ----------API catalog
  const { allowed: canVendorCreateApiCatalog } = usePermission({
    permission: vendorCreateApiCatalogPermission,
  });
  const { allowed: canVendorViewApiCatalog } = usePermission({
    permission: vendorViewApiCatalogPermission,
  });

  const { allowed: canAdminCreateApiCatalog } = usePermission({
    permission: adminCreateApiCatalogPermission,
  });

  const showApiCatalogSidebarItem =
    canVendorCreateApiCatalog ||
    canVendorViewApiCatalog ||
    canAdminCreateApiCatalog;

  // ----------Event grid
  const { allowed: canCreateEvent } = usePermission({
    permission: eventCreationPermission,
  });
  const { allowed: canVendorViewEvent } = usePermission({
    permission: eventVendorViewPermission,
  });
  const { allowed: canAdminViewEvent } = usePermission({
    permission: eventAdminViewPermission,
  });

  const { allowed: isAdminFunctionVisible } = usePermission({
    permission: policyEntityReadPermission,
    resourceRef: 'policy-entity',
  });

  const showEventAndSubscriptionSidebarItem =
    canCreateEvent || canVendorViewEvent || canAdminViewEvent;

  // ----------Vendor and Systems
  const { allowed: canCreateVendor } = usePermission({
    permission: vendorCreationPermission,
  });
  const { allowed: canCreateSystem } = usePermission({
    permission: systemCreationPermission,
  });

  const showVendorsAndSystemsSidebarItem = canCreateVendor || canCreateSystem;

  return (
    <SidebarPage>
      <Sidebar>
        <SidebarLogo />
        <SidebarDivider />
        <SidebarGroup label="Menu" icon={<MenuIcon />}>
          <SidebarItem id="home" icon={HomeIcon} to="/">
            <Typography variant="body1" style={{ fontWeight: '500' }}>
              Home
            </Typography>
          </SidebarItem>
          {showServiceRequestSidebarItem && (
            <CollapsibleSidebarGroup icon={ExtensionIcon} text="Resources">
              {(canViewServiceRequest || canAdminViewServiceRequest) && (
                <SidebarItem
                  id="service-request-list"
                  icon={Kitchen}
                  to="service-request/list"
                  text="Catalog"
                />
              )}
              <RequirePermission
                permission={serviceRequestCreatePermission}
                errorPage
              >
                <SidebarItem
                  id="service-request-add-new"
                  icon={Ballot}
                  to="service-request/create"
                  text="Request New"
                />
              </RequirePermission>
            </CollapsibleSidebarGroup>
          )}

          {showApiCatalogSidebarItem && (
            <CollapsibleSidebarGroup icon={AllInboxIcon} text="API & Errors">
              {(canVendorViewApiCatalog || canAdminCreateApiCatalog) && (
                <SidebarItem
                  id="service-catalog-list"
                  icon={Kitchen}
                  to="service-catalog/list"
                  text="Catalog"
                />
              )}
              {(canVendorCreateApiCatalog || canAdminCreateApiCatalog) && (
                <SidebarItem
                  id="service-catalog-add-new"
                  icon={Ballot}
                  to="service-catalog/create"
                  text="Add New"
                />
              )}
            </CollapsibleSidebarGroup>
          )}

          {showEventAndSubscriptionSidebarItem && (
            <CollapsibleSidebarGroup
              icon={MapIcon}
              text="Events & Subscription"
            >
              {(canVendorViewEvent || canAdminViewEvent) && (
                <SidebarItem
                  id="event-grid-list"
                  icon={Kitchen}
                  to="event-grid/list"
                  text="Catalog"
                />
              )}
              {canCreateEvent && (
                <SidebarItem
                  id="event-grid-add-new"
                  icon={Ballot}
                  to="event-grid/create"
                  text="Add New"
                />
              )}
            </CollapsibleSidebarGroup>
          )}

          {showVendorsAndSystemsSidebarItem && (
            <CollapsibleSidebarGroup icon={MapIcon} text="Vendors & Systems">
              {canCreateVendor && (
                <SidebarItem
                  id="vendor-add-new"
                  icon={Kitchen}
                  to="vendor/creation"
                  text="Add New Vendor"
                />
              )}
              {canCreateSystem && (
                <SidebarItem
                  id="system-add-new"
                  icon={Ballot}
                  to="system/creation"
                  text="Add New System"
                />
              )}
            </CollapsibleSidebarGroup>
          )}

          <SidebarItem
            id="community"
            icon={SupervisedUserCircleIcon}
            to="community"
          >
            <Typography variant="body1" style={{ fontWeight: '500' }}>
              Community
            </Typography>
          </SidebarItem>

          <CollapsibleSidebarGroup icon={LibraryBooks} text="Documentation">
            <SidebarItem
              id="data-model"
              icon={DataUsageIcon}
              to="information"
              text="Information"
            />
            <SidebarItem
              id="data-model"
              icon={DataUsageIcon}
              to="documentation"
              text="Data Model"
            />
            <SidebarItem
              id="contact-us"
              icon={ContactMailIcon}
              to="documentation"
              text="Contact us"
            />
            <SidebarItem
              id="support-process"
              icon={Kitchen}
              to="documentation"
              text="Support Process"
            />
          </CollapsibleSidebarGroup>
        </SidebarGroup>

        <SidebarDivider />

        <SidebarSpace />
        <SidebarDivider />

        {/* <SidebarGroup
          id="settings"
          label="Profile & Settings"
          // icon={<UserSettingsSignInAvatar />}
          to="/settings"
        >
          <SidebarSettings />
        </SidebarGroup> */}

        <SidebarItem id="settings" icon={Settings} to="settings">
          <Typography variant="body1" style={{ fontWeight: '500' }}>
            Settings
          </Typography>
        </SidebarItem>

        {isAdminFunctionVisible && (
          <CollapsibleSidebarGroup
            icon={SupervisorAccount}
            text="Admin Functions"
          >
            <Administration />
            <SidebarItem
              id="data-model"
              icon={GroupIcon}
              to="team-administration"
              text="Team Administration"
            />
          </CollapsibleSidebarGroup>
        )}

        {/* <SidebarGroup
          id="administration"
          label="Admin Functions"
          icon={<SupervisorAccount />}
        >
          <Administration />
         
        </SidebarGroup> */}
      </Sidebar>
      {children}
    </SidebarPage>
  );
};
