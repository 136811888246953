import { createRouteRef } from '@backstage/core-plugin-api';

export const myServiceCatalogCreateRouteRef = createRouteRef({
  id: 'service-catalog',
});

export const myServiceCatalogListRouteRef = createRouteRef({
  id: 'service-catalog-list',
});

export const myServiceCatalogDetailViewRouteRef = createRouteRef({
  id: 'service-catalog-view',
  params: ['id', 'swaggerFile'],
});
