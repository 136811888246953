import { createRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'home',
});

export const landingPageRouteRef = createRouteRef({
  id: 'landing',
});

export const dashboardPageRouteRef = createRouteRef({
  id: 'dashboard',
});

export const detailedArticlePageRouteRef = createRouteRef({
  id: 'detailedArticle',
});

export const faqPageRouteRef = createRouteRef({
  id: 'faq',
});

export const informationPageRouteRef = createRouteRef({
  id: 'information',
});
